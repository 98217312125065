jQuery(document).ready(function ($) {
    if ($('#homeVideo').length > 0) {
        if (window.innerWidth >= 1400) {
            $('#homeVideo').html(`<video id="homeVideo" autoplay loop muted playsinline preload="none">
			<source src="https://heatandcoolaircon.co.uk/wp-content/themes/heatAndCoolTheme/images/Mp4-1.m4v" type="video/mp4">
		</video>`);
        } else if (window.innerWidth <= 1400 && window.innerWidth >= 900) {
            $('#homeVideo').html(`<video id="homeVideo" autoplay loop muted playsinline preload="none">
			<source src="https://heatandcoolaircon.co.uk/wp-content/themes/heatAndCoolTheme/images/Mp4-1-1small.m4v" type="video/mp4">
		</video>`);
        } else {
            $('#homeVideo').html(`<video id="homeVideo" autoplay loop muted playsinline preload="none">
			<source src="https://heatandcoolaircon.co.uk/wp-content/themes/heatAndCoolTheme/images/Mp4-1-1smallest.m4v" type="video/mp4">
		</video>`);
        }
    }

});