jQuery(document).ready(function ($) {

    $('#slick').slick({
        dots: false,
        arrows:false,
        infinite: true,
        autoplay:true,
        speed: 3000,
        cssEase: 'linear',
        autoplaySpeed:10,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });
    $('.fade-slider').slick({
        dots: true,
        arrows:false,
        infinite: true,
        autoplay:true,
        speed: 500,
        fade: true,
        cssEase: 'linear'
      });

      
    $('.fade-slider-2').slick({
        dots: false,
        arrows:false,
        infinite: true,
        autoplay:true,
        speed: 3000,
        cssEase: 'linear',
        autoplaySpeed:10,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });
});

jQuery(document).ready(function() {
    if($('.fade-slider-2').length){

        $('.fade-slider-2').each(function () {
            
            $(this).magnificPopup({
                delegate: '.image', // the selector for gallery item
                type: 'image',
                gallery: {
                    enabled:true
                }
            });
        })
    }
});