jQuery(document).ready(function ($) {
    let page = 1;
    // get amount of pages in form
    const max = $('.form .page').length;
    // on click shows next page
    $("#next").click(() => {
        check();
        // page += 1;
        // update();
    })
    // on click shows previous page
    $("#previous").click(() => {
        page -= 1;
        update();
    })
    //previous button on last page
    $(".previous-last").click((event) => {
        page -= 1;
        update();
        event.preventDefault()
    })
    $(".label1").click(function () {
        if ($(this).hasClass('activebutton')) {
            page = 1;
            update();
        }
    })
    $(".label2").click(function () {
        if ($(this).hasClass('activebutton')) {
            page = 2;
            update();
        }
    })
    $(".label3").click(function () {
        if ($(this).hasClass('activebutton')) {
            page = 3;
            update();
        }
    })
    $(".label4").click(function () {
        if ($(this).hasClass('activebutton')) {
            page = 4;
            update();
        }
    })
    $(".label5").click(function () {
        if ($(this).hasClass('activebutton')) {
            page = 5;
            update();
        }
    })
    $(".label6").click(function () {
        if ($(this).hasClass('activebutton')) {
            page = 6;
            update();
        }
    })
    $(".label7").click(function () {
        if ($(this).hasClass('activebutton')) {
            page = 7;
            update();
        }
    })
    $(".label8").click(function () {
        if ($(this).hasClass('activebutton')) {
            page = 8;
            update();
        }
    })
    $(".label9").click(function () {
        if ($(this).hasClass('activebutton')) {
            page = 9;
            update();
        }
    })

    let form = ".page" + page;
    let circle = ".circle" + page;
    let check = () => {
        let error = false;
        $(".rederror").remove();
        $(form + " .required").each(function (i, element) {
            $(this).after("");

            if ($(element).hasClass('email')) {
                const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (re.test(element.value)) {
                    $(this).removeClass("error");

                } else {
                    error = true;
                    $(this).addClass("error");
                    $(this).after("<p>Please enter a valid Email address</p>");

                }
            } else if ($(element).hasClass('number')) {
                var regex = /\d{5}$/;
                cleanreg = /\D/g;
                let clean = element.value.replace(cleanreg, "");


                if (regex.test(clean)) {
                    $(this).removeClass("error");
                    $(this).val(clean);


                } else {
                    error = true;
                    $(this).addClass("error");
                    $(this).after("<p class='rederror'>Please enter a valid number</p>");
                }
            }
            if (!element.value) {
                $(this).addClass("error");
                $(this).after("<p class='rederror'>This is a required field</p>");
                error = true;
            }
        });
        $(form + " .required-radio").each(function (i, element) {
            $(this).after("");
            let radioInput = $(this).find("input:checked");
            

            if (!radioInput.length) {
                $(this).addClass("error");
                $(this).after("<p class='rederror'>This is a required field</p>");
                error = true;
            }
        });
        $(form + " input").each(function (i, element) {
            
            if (!$(element).hasClass('required')) {
                if ($(element).hasClass('email')) {
                    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (re.test(element.value)) {
                        $(this).removeClass("error");
                        // console.log('email');
                        $(this).after("");

                    } else {
                        if ($(this).val()) {
                            error = true;
                            $(this).addClass("error");
                            $(this).after("<p>Please enter a valid Email address</p>");
                        }
                    }
                }
                if ($(element).hasClass('number')) {
                    var regex = /\d{5}$/;
                    cleanreg = /\D/g;
                    let clean = element.value.replace(cleanreg, "");


                    if (regex.test(clean)) {
                        $(this).removeClass("error");
                        $(this).after("");
                        $(this).val(clean);


                    } else {
                        if ($(this).val()) {
                            error = true;
                            $(this).addClass("error");
                            $(this).after("<p class='rederror'>Please enter a valid number</p>");
                        }

                    }
                }
            }
        });
        if (error == false) {
            page += 1;
            $(".error").removeClass("error");
            $(".rederror").remove();
            update();
        } else {
            let headerHeight=$('#header').outerHeight();
            $('html, body').animate({
                scrollTop: $("#form-section").offset().top-headerHeight
            }, 100);
            $(".error-message").removeClass("hide");
        }

        
    }
    let update = () => {
        form = ".page" + page;
        circle = ".circle" + page;
        $(".activelabel").removeClass("activelabel");
        for (let i = 0; i <= page; i++) {
            let thislabel = ".label" + i;
            $(thislabel).addClass('activelabel');
            $(thislabel).addClass('activebutton');
        }

        $(".show").removeClass("show");
        $(form).addClass("show");
        $(circle).addClass("active")

        // $("html, body").animate({
        //     scrollTop: 250
        // }, "slow");
        if (page == 1 | page == max) {
            $("#previous").css("display", "none");
        } else {
            $("#previous").css("display", "inline-block");
        }
        if (page == max) {
            $("#next").css("display", "none");
            $(".opacity").css("opacity", "0");
        } else {
            $("#next").css("display", "inline-block");
            $(".opacity").css("opacity", "1");
        }
        // $("html, body").animate({
        //     scrollTop: 50
        // }, "slow");
    }


    //custom radio 
    $(".wpcf7-radio").click(function () {
        if (this.id != "unit" && this.id != "colour" && this.id != "wifi") {
            
            check();
        }
    })


    $('.wpcf7-list-item-label').click(function () {


        setTimeout(() => {

            $(".wpcf7-list-item").each(function () {
                if ($(this).find('input').prop('checked')) {
                    $(this).find('.wpcf7-list-item-label').addClass("active");
                } else {
                    $(this).find('.wpcf7-list-item-label').removeClass("active");

                }
            });
        }, 200);

    })

    if (('#wpcf7-f497-o1').length) {

        document.addEventListener('wpcf7mailsent', function (event) {
            location = 'https://heatandcoolaircon.co.uk/thank-you/';
        }, false);
    }
    if (('#wpcf7-f517-o1').length) {

        document.addEventListener('wpcf7mailsent', function (event) {
            location = 'https://heatandcoolaircon.co.uk/thank-you/';
        }, false);
    }
    if (('#wpcf7-f544-o1').length) {

        document.addEventListener('wpcf7mailsent', function (event) {
            location = 'https://heatandcoolaircon.co.uk/thank-you/';
        }, false);
    }
    if (('#wpcf7-f537-o1').length) {

        document.addEventListener('wpcf7mailsent', function (event) {
            location = 'https://heatandcoolaircon.co.uk/thank-you/';
        }, false);
    }
//check if user is logged in

    loggedIn=  $('.login-data').data('loggedin');
    
    if($('.hide-field').length&&loggedIn){
        $('.hide-field').val('Office Form')
    }

});