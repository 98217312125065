jQuery(document).ready(function ($) {
    // $('#header_nav .menu-item').mouseenter(
    //     function () {
    //         //check to see if menu-item is part of a submenu
    //         if (!$(this).parent().hasClass('sub-menu')) {
    //             $('.sub-menu').removeClass('open');
    //             $('#header').removeClass('open');

    //             $(this).find('.sub-menu').addClass('open');

    //             $(this).addClass('open');
    //             if ($('.sub-menu').hasClass('open')) {
    //                 $('#header').addClass('open');
    //             }

    //         }
    //     }
    // );
    // $('#header_nav .menu-item').mouseleave(
    //     function () {
    //         //check to see if menu-item is part of a submenu
    //         if (!$(this).parent().hasClass('sub-menu')) {
    //             $('.sub-menu').removeClass('open');
    //             $(this).removeClass('open');
    //             $('#header').removeClass('open');

    //         }
    //     }
    // );

    $(".mobile-nav-button").click(function () {
        $(".nav-closed").toggleClass("open");
        $(".bar1").toggleClass("change");
        $(".bar2").toggleClass("change");
        $(".bar3").toggleClass("change");
    });


    let open = 0;



    $('#menu-item-120').mouseenter(function () {
        open = 1;
        $('.nav-box').addClass('flexopen');
        $('.nav-box').removeClass('none');

        if (window.innerWidth >= 1199) {
            $('.active-class').removeClass('active-class');
            let headerColWidth = $('.domestic-col').outerWidth();
            let headerColHeight = $('.domestic-col').outerHeight();
            // console.log(headerColWidth);
            // console.log(headerColHeight);

            $('.nav-box').css({
                'height': headerColHeight,
                'width': headerColWidth,
                'left':'15px'
            });
            $('.domestic-col').addClass('active-class')
        }
    });

    $('#menu-item-121').mouseenter(function () {
        open = 1;
        $('.nav-box').addClass('flexopen');
        $('.nav-box').removeClass('none');
       
        if (window.innerWidth >= 1199) {
            $('.active-class').removeClass('active-class');
            let headerColWidth = $('.business-col').outerWidth();
            let headerColHeight = $('.business-col').outerHeight();

            $('.nav-box').css({
                'height': headerColHeight,
                'width': headerColWidth,
                'left':'14%'
            });
            $('.business-col').addClass('active-class')
        }
    });
    
    $('#menu-item-124').mouseenter(function () {
        open = 1;
        $('.nav-box').addClass('flexopen');
        $('.nav-box').removeClass('none');

        if (window.innerWidth >= 1199) {
            $('.active-class').removeClass('active-class');
            let headerColWidth = $('.maintenence-col').outerWidth();
            let headerColHeight = $('.maintenence-col').outerHeight();

            $('.nav-box').css({
                'height': headerColHeight,
                'width': headerColWidth,
                'left':'25%'
            });
            $('.maintenence-col').addClass('active-class')
        }
    });
    $('.nav-closed').mouseleave(function () {
        open = 0;
        $('.active-class').removeClass('active-class');
        setTimeout(() => {
            if (open == 0) {
            $('.nav-box').removeClass('flexopen');
                $('.nav-box').addClass('none');
            }
        }, 100);
    });


    $('.blocks').click(function () {

        $(this).toggleClass("opensmall");
    });
    $('.blocks a').click(function () {
        $(".nav-closed").toggleClass("open");
        $(".bar1").toggleClass("change");
        $(".bar2").toggleClass("change");
        $(".bar3").toggleClass("change");
    });


});