jQuery(document).ready(function ($) {
    AOS.init();
    // hero animation
    $('#hero .content .text').css("opacity", 1);
    //scroll down arrow on Archive Projects
    $(".down").click(function () {
        var hash = document.getElementById("project-container");
        $('html, body').animate({
            scrollTop: $(hash).offset().top
        }, 800, function () {
            window.location.hash = hash;
        });
    })

    $("a[href*='" + location.pathname + "']").addClass("current");

    // let thispercent = $(".percent").data('percent');
    // console.log(thispercent);
    // $(".percent").text("0%")

    // let current = 0;

    // function scrollup() {
    //     let speedpercent = ((thispercent / 100) * current) + 1;
    //     let speed = ((speedpercent)) * 15;

    //     setInterval(() => {

    //         if (current < thispercent) {
    //             console.log(speed);
    //             $(".percent").text((current + 1) + "%");
    //             current = current + 1;
    //         }
    //     }, speed);
    // }
    // $(window).on('scroll', function () {
    //     if ($(".percent").is(":in-viewport(-500)")) {
    //         scrollup();
    //     }
    // })

    //covid rotation
    if ($('#covid').length) {
        $("#covid #hero ul li").append('<div class="fan"></div>');
        let rotate = 0;
        setInterval(() => {

            $("#covid #hero ul .fan").css("transform", `rotate(${rotate}deg)`);
            rotate += 1;


        }, 10);
    }

    //hover footer
    $(".hover-open").mouseenter(() => {
        $(".closed").addClass("openthis")
        $(".spin").addClass("openthis")
    })

    $(".hover-open").mouseleave(() => {
        $(".openthis").removeClass("openthis")
    })
});